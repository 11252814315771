













































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    AddWinnerPrepare: () => import('./add-winner-prepare.vue'),
    AddWinnerConfirm: () => import('./add-winner-confirm.vue'),
    AddWinnerSending: () => import('./add-winner-sending.vue')
  }
})
export default class Stepper extends Vue {
  @Prop() currentStep!: number

  step1 = 1
  step2 = 2
  step3 = 3
}
